import React, { useEffect, useState } from "react";
import KSData from "../KS.json";
import CountdownTimer from "./CountdownTimer";
import Datetime from "./Datetime";
import { Typography, Card } from "@material-tailwind/react";

const TABLE_HEAD = [
  "Sabahu",
  "Lindja e diellit",
  "Dreka",
  "Ikindia",
  "Akshami",
  "Jacia",
];

const KohetEnamazit = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = () => {
    setProducts(KSData);
  };

  const getCurrentDate = () => {
    const today = new Date();
    const month = today.getMonth() + 1;
    const date = today.getDate();
    return { month, date };
  };

  const { month, date } = getCurrentDate();

  const filteredProducts = products.filter(
    (product) => product.Month === month && product.Date === date
  );
  /// Koha e Sabahut
  const calculateSabahu = (imsaku) => {
    const [hours, minutes] = imsaku.split(":");
    const imsakuTime = new Date();
    imsakuTime.setHours(hours);
    imsakuTime.setMinutes(minutes);
    imsakuTime.setMinutes(imsakuTime.getMinutes() + 30);

    const sabahuHours = imsakuTime.getHours().toString().padStart(2, "0");
    const sabahuMinutes = imsakuTime.getMinutes().toString().padStart(2, "0");

    return `${sabahuHours}:${sabahuMinutes}`;

  };

  return (
    <div className="">
      <figure className="relative flex justify-center  w-full h-full bg-blue-gray-800  ">
        <figcaption className="flex justify-center text-center w-80 sm:w-96  py-4 ">

          <Typography variant="h3" color="white" >
            Kohët e namazit për Kosovë
          </Typography>
        </figcaption>
      </figure>
      <figure className="relative  h-full w-full   flex justify-center ">

        <figcaption className="absolute  w-full   ">
          <Card className="  h-full w-full pt-4 pb-10 px-4 items-center text-center bg-blue-gray-800 rounded-none">

            <figcaption className=" w-80  h-min rounded-xl border border-white bg-white/75  px-4  ">
              <div>
                <Datetime hideTime />
              </div>
              <Typography variant="h4" color="blue-gray" >
                <CountdownTimer />
              </Typography>
            </figcaption>

            {filteredProducts.map((product) => (
              <div key={`${product.Month}-${product.Date}`} className="flex flex-col w-96  gap-8  max-w-[320px]  mt-2  border border-blue-gray-200  bg-white/75 rounded-xl p-4 ">
                <div className="flex justify-between items-center border-b-[1.5px] border-dashed  border-blue-gray-400 ">
                  <div className="flex justify-between items-center gap-4">
                    <div>
                      <img src="iconsTakvim/sabahu.png" alt="Sabahu" width={30} height={30}></img>
                    </div>
                    <div>
                      <Typography
                        variant="lead"
                        color="black"
                        className="font-semibold leading-none opacity-70" >{TABLE_HEAD[0]}</Typography>
                    </div>
                  </div>
                  <div>
                    <Typography
                      variant="lead"
                      color="black"
                      className="font-semibold leading-none opacity-70">{calculateSabahu(product.Imsaku)}</Typography>
                  </div>
                </div>
                <div className="flex justify-between items-center border-b-[1.5px] border-dashed  border-blue-gray-400 ">
                  <div className="flex justify-between items-center gap-4">
                    <div>
                      <img src="iconsTakvim/sunrise.png" alt="Lindja e diellit" width={30} height={30}></img>
                    </div>
                    <div>
                      <Typography
                        variant="lead"
                        color="black"
                        className="font-semibold leading-none opacity-70">{TABLE_HEAD[1]}</Typography>
                    </div>
                  </div>
                  <div>
                    <Typography
                      variant="lead"
                      color="black"
                      className="font-semibold leading-none opacity-70">{product["Lindja e diellit"]}</Typography>
                  </div>
                </div>
                <div className="flex justify-between items-center border-b-[1.5px] border-dashed  border-blue-gray-400 ">
                  <div className="flex justify-between items-center gap-4">
                    <div>
                      <img src="iconsTakvim/dreka.png" alt="Dreka" width={30} height={30}></img>
                    </div>
                    <div><Typography
                      variant="lead"
                      color="black"
                      className="font-semibold leading-none opacity-70">{TABLE_HEAD[2]}</Typography>
                    </div>
                  </div>
                  <div>
                    <Typography
                      variant="lead"
                      color="black"
                      className="font-semibold leading-none opacity-70">{product.Dreka}</Typography>
                  </div>
                </div>
                <div className="flex justify-between items-center border-b-[1.5px] border-dashed  border-blue-gray-400 ">
                  <div className="flex justify-between items-center gap-4">
                    <div>
                      <img src="iconsTakvim/ikindia.png" alt="Ikindia" width={30} height={30}></img>
                    </div>
                    <div>
                      <Typography
                        variant="lead"
                        color="black"
                        className="font-semibold leading-none opacity-70">{TABLE_HEAD[3]}</Typography>
                    </div>
                  </div>
                  <div>
                    <Typography
                      variant="lead"
                      color="black"
                      className="font-semibold leading-none opacity-70">{product.Ikindia}</Typography>
                  </div>
                </div>
                <div className="flex justify-between items-center border-b-[1.5px] border-dashed  border-blue-gray-400 ">
                  <div className="flex justify-between items-center gap-4">
                    <div>
                      <img src="iconsTakvim/sunset.png" alt="Akshami" width={30} height={30}></img>
                    </div>
                    <div>
                      <Typography
                        variant="lead"
                        color="black"
                        className="font-semibold leading-none opacity-70">{TABLE_HEAD[4]}</Typography>
                    </div>
                  </div>
                  <div>
                    <Typography
                      variant="lead"
                      color="black"
                      className="font-semibold leading-none opacity-70">{product.Akshami}</Typography>
                  </div>
                </div>
                <div className="flex justify-between items-center border-b-[1.5px] border-dashed  border-blue-gray-400 ">
                  <div className="flex justify-between items-center gap-4">
                    <div>
                      <img src="iconsTakvim/jacia.png" alt="Jacia" width={30} height={30}></img>
                    </div>
                    <div>
                      <Typography
                        variant="lead"
                        color="black"
                        className="font-semibold leading-none opacity-70">{TABLE_HEAD[5]}</Typography>
                    </div>
                  </div>
                  <div>
                    <Typography
                      variant="lead"
                      color="black"
                      className="font-semibold leading-none opacity-70">{product.Jacia}</Typography>
                  </div>
                </div>
              </div>
            ))}
          </Card>
        </figcaption>
      </figure>
    </div>
  );
};

export default KohetEnamazit;
