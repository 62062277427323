import MiddleNightTime from "./components/MiddleNightTime/MiddleNightTime";
import LastThirdTime from "./components/LastThirdTime/LastThirdTime";
import KohetEnamazit from "./components/KohetEnamazit";
import ImportantDates from "./components/ImportantDates/ImportantDates";
import { Route, Routes } from "react-router-dom";

function App() {
  return (
        <Routes>
          <Route path="/" element={<KohetEnamazit />} />
          <Route path="/middleNightTime" element={<MiddleNightTime />} />
          <Route path="/lastThirdTime" element={<LastThirdTime />} />
          <Route path="/importantDates" element={<ImportantDates />} />
        </Routes>
  );
}

export default App;
