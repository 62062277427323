import { Typography, Card, Button } from "@material-tailwind/react";
import React, { useState, useRef } from "react";



const ManualTime = ({ showMidnight }) => {
  const maghribHourRef = useRef(null);
  const maghribMinuteRef = useRef(null);
  const fajrHourRef = useRef(null);
  const fajrMinuteRef = useRef(null);
  const calcButton = useRef(null);



  const [lastThirdOfTheNight, setLastThirdOfTheNight] = useState("");
  const [midnight, setMidnight] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const roundToNearest30 = (date) => {
    
    const sec = 60;
    const ms = 1000 * sec;
    return new Date(Math.ceil(date.getTime() / ms) * ms);
  };

  const validateHourInput = (value) => {
    const hour = parseInt(value);
    return hour >= 0 && hour <= 23;
  };

  const validateMinuteInput = (value) => {
    const minute = parseInt(value);
    return minute >= 0 && minute <= 59;
  };

  const handleHourInput = (e, nextInputRef) => {
    const { value } = e.target;
    if (!validateHourInput(value)) {
      e.target.value = "";
    } else if (value.length === 2 && nextInputRef) {
      nextInputRef.current.focus();
    }
  };

  const handleMinuteInput = (e, nextInputRef) => {
    const { value } = e.target;
    if (!validateMinuteInput(value)) {
      e.target.value = "";
    } else if (value.length === 2 && nextInputRef) {
      nextInputRef.current.focus();
    }
  };

  const calculateTimes = () => {
    const maghribHour = document.getElementById("maghribHour").value;
    const maghribMinute = document.getElementById("maghribMinute").value;
    const fajrHour = document.getElementById("fajrHour").value;
    const fajrMinute = document.getElementById("fajrMinute").value;

    if (
      maghribHour === "" ||
      maghribMinute === "" ||
      fajrHour === "" ||
      fajrMinute === ""
    ) {
      setErrorMessage(
        "Ju lutem shkruani kohën e namazit të akshamit lart, ndërsa poshtë shkruani fillimin e kohës së namazit të sabahut"
      );
    } else {
      setErrorMessage(""); // Clear the error message
      const currentDate = new Date();
      const maghribTime = roundToNearest30(
        new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate(),
          maghribHour,
          maghribMinute,
        )
      );
      const fajrTime = roundToNearest30(
        new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate() + 1,
          fajrHour,
          fajrMinute,
        )
      );
      const difference = fajrTime.getTime() - maghribTime.getTime();
      const thirdOfTheDifference = difference / 3;
      const halfOfTheDifference = difference / 2;
      const lastThirdTime = roundToNearest30(
        new Date(fajrTime.getTime() - thirdOfTheDifference)
      ).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
      console.log(lastThirdTime);
      // Adjust the hours if it is "24" and change it to "00"
      const adjustedLastThirdTime = lastThirdTime.replace(/^24/, "00");
      const midnightTime = roundToNearest30(
        new Date(fajrTime.getTime() - halfOfTheDifference)
      ).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
      // Adjust the hours if it is "24" and change it to "00"
      const adjustedMidnightTime = midnightTime.replace(/^24/, "00");
      setLastThirdOfTheNight(adjustedLastThirdTime);
      setMidnight(adjustedMidnightTime);
    }
  };

  return (
    <>
      <figure className="relative   flex flex-col items-center justify-center  w-full h-full bg-blue-gray-500 pb-10">
        <figcaption className="flex justify-center w-80 sm:w-96  py-4  ">
          <Typography variant="h3" color="white" >
            Llogaritja manuale
          </Typography>
        </figcaption>
     
        <figcaption>
       
          <Card className="h-full w-80 p-6 items-center border border-white bg-white/75 ">
            <Typography align="center" variant="h6" color="blue-gray">
              Namazi i akshamit:
              <div className="flex justify-center gap-1 py-4 items-center">
                <div className="relative h-10">
                  <input
                    type="number"
                    onInput={(e) => handleHourInput(e, maghribMinuteRef)}
                    id="maghribHour"
                    ref={maghribHourRef}
                    className="peer h-full bg-transparent text-blue-gray-700 font-sans font-bold outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-lg pl-6 pr-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-blue-500  w-24"
                    placeholder=" "
                  />
                  <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-bold truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-blue-gray-400 peer-focus:text-blue-500 before:border-blue-gray-200 peer-focus:before:!border-blue-500 after:border-blue-gray-200 peer-focus:after:!border-blue-500">
                    Ora:{" "}
                  </label>
                </div>
                :
                <div className="relative h-10">
                  <input
                    type="number"
                    onInput={(e) => handleMinuteInput(e, fajrHourRef)}
                    id="maghribMinute"
                    ref={maghribMinuteRef}
                    className="peer h-full bg-transparent text-blue-gray-700 font-sans font-bold outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-lg pl-6 pr-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-blue-500  w-24"
                    placeholder=" "
                  />
                  <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-bold truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-blue-gray-400 peer-focus:text-blue-500 before:border-blue-gray-200 peer-focus:before:!border-blue-500 after:border-blue-gray-200 peer-focus:after:!border-blue-500">
                    Minutat:{" "}
                  </label>
                </div>
              </div>
            </Typography>
            <Typography align="center" variant="h6" color="blue-gray">
              Namazi i sabahut:
              <div className="flex justify-center gap-1 py-4 items-center">
                <div className="relative h-10 ">
                  <input
                    type="number"
                    onInput={(e) => handleHourInput(e, fajrMinuteRef)}
                    id="fajrHour"
                    ref={fajrHourRef}
                    className="peer h-full bg-transparent text-blue-gray-700  font-sans font-bold outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-lg pl-6 pr-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-blue-500  w-24"
                    placeholder=" "
                  />
                  <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-bold truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-blue-gray-400 peer-focus:text-blue-500 before:border-blue-gray-200 peer-focus:before:!border-blue-500 after:border-blue-gray-200 peer-focus:after:!border-blue-500">
                    Ora:{" "}
                  </label>
                </div>
                :
                <div className="relative h-10">
                  <input
                    type="number"
                    onInput={(e) => handleMinuteInput(e, calcButton)}
                    id="fajrMinute"
                    ref={fajrMinuteRef}
                    className="peer h-full bg-transparent text-blue-gray-700 font-sans font-bold outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-lg pl-6 pr-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-blue-500  w-24"
                    placeholder=" "
                  />
                  <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-bold truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-blue-gray-400 peer-focus:text-blue-500 before:border-blue-gray-200 peer-focus:before:!border-blue-500 after:border-blue-gray-200 peer-focus:after:!border-blue-500">
                    Minutat:{" "}
                  </label>
                </div>
              </div>
            </Typography>
            <div className="py-4 flex justify-center">
              <Button ref={calcButton} className="calculate bg-blue-500 text-md" onClick={(e) => calculateTimes(e, null)}>
                Llogarit
              </Button>
            </div>
            {errorMessage && (
              <div className="error text-center">{errorMessage}</div>
            )}
            {showMidnight && lastThirdOfTheNight && (
              <div>
                <Typography
                  align="center"
                  variant="h5"
                  color="blue-gray"
                  id="midnight"
                >
                  {`Gjysma e natës fillon në ora: ${midnight}`}{" "}
                </Typography>
              </div>
            )}
            {!showMidnight && lastThirdOfTheNight && (
              <div>
                <Typography
                  align="center"
                  variant="h5"
                  color="blue-gray"
                  id="third"
                >{`Pjesa e tretë e natës fillon në ora: ${lastThirdOfTheNight}`}</Typography>
              </div>
            )}
          </Card>
        </figcaption>
      </figure>
    </>
  );
};

export default ManualTime;
