import { Typography } from "@material-tailwind/react";

import { useEffect, useState } from "react";
import KSData from "../../KS.json";
import Datetime from "../Datetime";
import ManualTime from "../ManualTime";

const MiddleNightTime = () => {
  const [mesiNates, setMesiNates] = useState("");
  useEffect(() => {
    const jsonData = KSData;

    // Function to convert time to minutes since midnight
    function timeToMinutes(time) {
      const [hours, minutes] = time.split(":");
      const parsedHours = parseInt(hours, 10) + 12;

      return parsedHours * 60 + parseInt(minutes);
    }
    // Function to convert minutes since midnight to time string
    function minutesToTime(minutes) {
      const hours = Math.floor(minutes / 60);
      const mins = minutes % 60;
      const secs = (minutes * 60) % 60;

      return `${hours.toString().padStart(2, "0")}:${mins
        .toString()
        .padStart(2, "0")}:${secs
          .toString()
          .padStart(2, "0")}`;
    }
    // Get today's date
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Months are zero-based
    const currentDay = currentDate.getDate();

    const maghribTime = jsonData.find(
      (data) => data.Month === currentMonth && data.Date === currentDay
    );


    const nextDate = new Date(currentDate);
    nextDate.setDate(currentDate.getDate() + 1);
    const nextDayMonth = nextDate.getMonth() + 1; // Months are zero-based
    const nextDay = nextDate.getDate();

    const fajrTime = jsonData.find(
      (data) => data.Month === nextDayMonth && data.Date === nextDay
    );

    let mesiNatesMinutes; // Declare mesiNatesMinutes outside the if statement
    if (currentDay && nextDay) {
      const getMaghribTime = maghribTime["Akshami"];
      const getFajrTime = fajrTime["Imsaku"];
      const minutes1 = timeToMinutes(getMaghribTime);
      const minutes2 = timeToMinutes(getFajrTime);
      const sabahu = minutes2 + 30;

      const averageMinutes = Math.floor((minutes1 + sabahu) / 2);
      mesiNatesMinutes = averageMinutes;


      const mesiNatesTime = minutesToTime(mesiNatesMinutes);

      // Check if the hour is 24 and adjust to -00
      let [hours, minutes] = mesiNatesTime.split(":");
      if (parseInt(hours, 10) === 24) {
        hours = "00";
      }
      const adjustedMesiNatesTime = `${hours}:${minutes}`;
      setMesiNates(adjustedMesiNatesTime);
    }
    
  }, []);
 


  return (
    <div className="">
      <figure className="relative  flex flex-col items-center justify-center w-full h-60 bg-blue-gray-800 ">

        <figcaption className=" w-80 sm:w-96  py-4 ">
          <Typography variant="h3" color="white" className="text-center pb-1">
            Gjysma e natës
          </Typography>
          <Typography variant="small" color="white" className="text-center ">
            Llogaritja  sipas takvimit të Kosovës (+ ose - 1 min)
          </Typography>
        </figcaption>
        <figcaption className="relative w-80   h-min rounded-xl border border-white bg-white/75 py-4 px-6  ">
          <div>
            <Typography variant="h5" color="blue-gray" className="mb-2">
              Gjysma e natës
            </Typography>
            <Datetime hideTime />
          </div>
          <Typography variant="h2" color="blue-gray" className="absolute right-4  top-2">
          {mesiNates} 
          </Typography>
          {/* <h2>{countdown}</h2> */}
        </figcaption>
        
      </figure>
     

      <ManualTime showMidnight={true} />
    
    </div>
  );
};

export default MiddleNightTime;
