import { Typography } from "@material-tailwind/react";

import React, { useEffect, useState } from "react";
import KSData from "../../KS.json";
import ManualTime from "../ManualTime";

const LastThirdTime = () => {
  const [result, setResult] = useState("");
  const [countdown, setCountdown] = useState("");

  useEffect(() => {
    const jsonData = KSData;

    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Months are zero-based
    const currentDay = currentDate.getDate();

    const maghribTime = jsonData.find(
      (data) => data.Month === currentMonth && data.Date === currentDay
    );

    const nextDate = new Date(currentDate);
    nextDate.setDate(currentDate.getDate() + 1);
    const nextDayMonth = nextDate.getMonth() + 1; // Months are zero-based
    const nextDay = nextDate.getDate();

    const fajrTime = jsonData.find(
      (data) => data.Month === nextDayMonth && data.Date === nextDay
    );

    const [maghribHours, maghribMinutes] = maghribTime.Akshami.split(":");
    const [fajrHours, fajrMinutes] = fajrTime.Imsaku.split(":");

    const sabahu = parseInt(fajrMinutes, 10) + 30;

    currentDate.setHours(maghribHours, maghribMinutes, 0); // Set seconds to 0
    nextDate.setHours(fajrHours, sabahu, 0); // Set seconds to 0

    const totalTime = nextDate.getTime() - currentDate.getTime();
    const lastThirdTime = totalTime / 3;

    const startingTimeOfLastThird = new Date(
      currentDate.getTime() + (totalTime - lastThirdTime)
    );

    let hours = startingTimeOfLastThird.getHours();
    if (hours > 23) {
      hours -= 24;
    }
    const timeString = `${hours
      .toString()
      .padStart(2, "0")}:${startingTimeOfLastThird
        .getMinutes()
        .toString()
        .padStart(2, "0")}`;
    setResult(timeString);

    // Calculate countdown
    const formatTime = (time) => {
      const hours = Math.floor(time / (1000 * 60 * 60))
        .toString()
        .padStart(2, "0");
      const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60))
        .toString()
        .padStart(2, "0");
      const seconds = Math.floor((time % (1000 * 60)) / 1000)
        .toString()
        .padStart(2, "0");
      // Adjust hours if it exceeds 24
      const adjustedHours =
        hours >= 24
          ? (parseInt(hours, 10) - 24).toString().padStart(2, "0")
          : hours;

      return `${adjustedHours}:${minutes}:${seconds}`;
    };

    const updateCountdown = () => {
      const currentTime = new Date().getTime();
      const startingTime = startingTimeOfLastThird.getTime();
      const timeLeft = startingTime - currentTime;
      setCountdown(formatTime(timeLeft));
    };

    // Update countdown initially
    updateCountdown();

    // Start countdown interval
    const countdownInterval = setInterval(updateCountdown, 1000);

    // Clear interval on component unmount
    return () => {
      clearInterval(countdownInterval);
    };
  }, []);

  return (
    <>
      <figure className="relative  gap-4 flex flex-col items-center justify-center  w-full h-80 bg-blue-gray-800">
        <figcaption className=" h-min w-80  text-center">
          <Typography variant="h3" color="white">
            Pjesa e tretë e natës
          </Typography>
          <Typography variant="small" color="white">
            Llogaritja  sipas takvimit të Kosovës (+ ose - 1 min)
          </Typography>
        </figcaption>
        {/* </figure>
      
       <div className=" flex  justify-center ">
        <figure className="relative top-20 flex  justify-center items-center w-full h-72 sm:h-96     bg-blue-gray-800  "> */}

        <figcaption className=" z-20 relative  w-80  rounded-xl border border-white bg-white/75 py-4 px-6  h-24 ">
          <Typography variant="h5" color="blue-gray" className="mb-2">
            Pjesa e tretë e natës fillon në ora:
            <span className="absolute bottom-2 right-4 text-3xl">{result}
            </span>
          </Typography>
        </figcaption>

        <figcaption className="relative  w-80 rounded-xl border border-white bg-white/75 py-4 px-6  h-24 ">
          <Typography variant="h5" color="blue-gray">
            Deri në pjesën e tretë të natës:
            <span className="absolute bottom-2 right-4 text-3xl">
              {countdown}
            </span>
          </Typography>
        </figcaption>
      </figure>
      {/* </div> */}

      {/* ///////////// */}

      {/* <figure className="relative top-24 h-full w-full p-4 flex justify-center">
        <img
          className="h-full w-max rounded-xl"
          src="middleNight.jpg"
          alt="nature"
        /> */}
      {/* <figcaption className="absolute top-20 left-2/4 flex w-[calc(100%-4rem)] -translate-x-2/4 justify-between rounded-xl border border-white bg-white/75 py-4 px-6 shadow-lg shadow-black/5 saturate-200 backdrop-blur-sm h-24 sm:w-max sm:left-12 sm:-translate-x-0"> */}
      {/* 
        <figcaption className="absolute -translate-y-0 w-80 mt-10 rounded-xl border border-white bg-white/75 py-4 px-6 shadow-lg shadow-black/5 backdrop-blur-sm h-24 sm:w-96 sm:-translate-y-0 sm:mt-32">
          <Typography variant="h5" color="blue-gray" className="mb-2">
            Pjesa e tretë e natës fillon në ora:
            <span className="absolute bottom-2 right-4 text-3xl">     {result}
            </span>
          </Typography>
        </figcaption>
        <figcaption className="absolute translate-y-36 w-80 rounded-xl border border-white bg-white/75 py-4 px-6 shadow-lg shadow-black/5 backdrop-blur-sm h-24 sm:w-96 sm:translate-y-60">
          <Typography variant="h5" color="blue-gray">
            Deri në pjesën e tretë të natës:
            <span className="absolute bottom-2 right-4 text-3xl">
              {countdown}
            </span>
          </Typography>
        </figcaption> */}

      {/* </figure> */}

      <div className="relative top-8">
        <ManualTime showMidnight={false} />
      </div>
    </>
  );
};

export default LastThirdTime;
