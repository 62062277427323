import React, { useEffect, useState } from "react";
import jsonData from "../KS.json";

const Countdown = () => {
  // Get current date
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1; // Months are zero-based
  const currentDay = currentDate.getDate();

  // Find prayer times for the current date in the JSON data
  const prayerTime = jsonData.find(
    (data) => data.Month === currentMonth && data.Date === currentDay
  );

  // Extract the prayer times from the found data
  const imsakuTime = parseTime(prayerTime.Imsaku);
  const sabahuTime = new Date(imsakuTime.getTime() + 30 * 60000); // Add 30 minutes to Imsaku
  const lindjaEDiellitTime = parseTime(prayerTime["Lindja e diellit"]);
  const drekaTime = parseTime(prayerTime.Dreka);
  const ikindiaTime = parseTime(prayerTime.Ikindia);
  const akshamiTime = parseTime(prayerTime.Akshami);
  const jaciaTime = parseTime(prayerTime.Jacia);

  const [currentTime, setCurrentTime] = useState(new Date());

  function parseTime(timeStr) {
    const [hours, minutes] = timeStr.split(":");
    const time = new Date();
    time.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0);
    return time;
  }

  const getDisplayTime = () => {
    if (currentTime <= sabahuTime) {
      return sabahuTime;
    } else if (currentTime <= lindjaEDiellitTime) {
      return lindjaEDiellitTime;
    } else if (currentTime <= drekaTime) {
      return drekaTime;
    } else if (currentTime <= ikindiaTime) {
      return ikindiaTime;
    } else if (currentTime <= akshamiTime) {
      return akshamiTime;
    } else if (currentTime <= jaciaTime) {
      return jaciaTime;
    } else {
      // Check if it's between Jaci and Sabahu
      const nextDaySabahu = new Date(currentTime);
      nextDaySabahu.setDate(currentDay + 1);
      nextDaySabahu.setHours(
        parseInt(sabahuTime.getHours(), 10),
        parseInt(sabahuTime.getMinutes(), 10),
        0,
        0
      );
      return nextDaySabahu;
    }
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const [nextPrayerTime, setNextPrayerTime] = useState(getDisplayTime());

  useEffect(() => {
    const nextPrayer = getDisplayTime();
    if (nextPrayer.getTime() !== nextPrayerTime.getTime()) {
      setNextPrayerTime(nextPrayer);
    }
  }, [currentTime, nextPrayerTime]);

  function getPrayerName(time) {
 if (time === sabahuTime) {
      return (
        <div className=" flex items-center gap-4">
          <img src="iconsTakvim/sabahu.png" alt="Sabahu" width={40} height={40}></img>
          Sabahu
        </div>
      )
    } else if (time === lindjaEDiellitTime) {
      return (
        <div className=" flex items-center gap-4">
          <img src="iconsTakvim/sunrise.png" alt="Lindja e diellit" width={40} height={40}></img>
          Lindja e diellit
        </div>
      )
    } else if (time === drekaTime) {
      return (
        <div className=" flex items-center gap-4">
          <img src="iconsTakvim/dreka.png" alt="Dreka" width={40} height={40}></img>
          Dreka
        </div>
      )
    } else if (time === ikindiaTime) {
      return (
        <div className=" flex items-center gap-4">
          <img src="iconsTakvim/ikindia.png" alt="Ikindia" width={40} height={40}></img>
          Ikindia
        </div>
      )
    } else if (time === akshamiTime) {
      return (
        <div className=" flex items-center gap-4">
          <img src="iconsTakvim/sunset.png" alt="Akshami" width={40} height={40}></img>
          Akshami
        </div>
      )
    } else if (time === jaciaTime) {
      return (
        <div className=" flex items-center gap-4">
          <img src="iconsTakvim/jacia.png" alt="Jacia" width={40} height={40}></img>
          Jacia
        </div>
      )
    } else {
      const nextPrayerTime = getDisplayTime();
      const remainingPrayerTimes = [
        sabahuTime,
        lindjaEDiellitTime,
        drekaTime,
        ikindiaTime,
        akshamiTime,
        jaciaTime,
      ];
      const futurePrayerTimes = remainingPrayerTimes.filter(
        (prayerTime) => prayerTime > nextPrayerTime
      );

      if (futurePrayerTimes.length > 0) {
        const nextPrayerTime = futurePrayerTimes[0];
        const index = remainingPrayerTimes.indexOf(nextPrayerTime);
        return index !== -1
          ? getPrayerName(remainingPrayerTimes[index])
          : "Error";
      } else {
        const nextDaySabahu = new Date(time);
        nextDaySabahu.setDate(currentDay + 1);
        nextDaySabahu.setHours(
          parseInt(sabahuTime.getHours(), 10),
          parseInt(sabahuTime.getMinutes(), 10),
          0,
          0
        );
        return <div className=" flex items-center gap-4">
          <img src="iconsTakvim/sabahu.png" alt="Sabahu" width={40} height={40}></img>
          Sabahu
        </div>;
      } 
    }
  }

  function getTimeRemaining() {
    const timeDiff = nextPrayerTime - currentTime;
    const totalSeconds = Math.floor(timeDiff / 1000);

    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    return {
      hours: formattedHours,
      minutes: formattedMinutes,
      seconds: formattedSeconds,
    };
  }

  const { hours, minutes, seconds } = getTimeRemaining();

  return (

    <div className="flex justify-center" >

      <h2>{getPrayerName(getDisplayTime())} edhe: {hours}:{minutes}:{seconds}</h2>
    </div>

  );
};

export default Countdown;
