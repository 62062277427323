import React, { useState, useEffect } from "react";
import { Typography } from "@material-tailwind/react";

const Datetime = ({ hideTime }) => {
  const [dateTime, setDateTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setDateTime(new Date());
    }, 1000); // Update every second

    return () => {
      clearInterval(interval); // Clear the interval when the component is unmounted
    };
  }, []);

  const daysInAlbanian = [
    "E Dielë",
    "E Hënë",
    "E Martë",
    "E Mërkurë",
    "E Enjëte",
    "E Premte",
    "E Shtunë",
  ];

  const monthsInAlbanian = [
    "Janar",
    "Shkurt",
    "Mars",
    "Prill",
    "Maj",
    "Qershor",
    "Korrik",
    "Gusht",
    "Shtator",
    "Tetor",
    "Nëntor",
    "Dhjetor",
  ];

  const formatDate = (date) => {
    const day = daysInAlbanian[date.getDay()];
    const month = monthsInAlbanian[date.getMonth()];
    const year = date.getFullYear();
    const numericDate = date.getDate();
    return `${day},  ${numericDate} ${month}, ${year}`;
  };

  const formatTime = (date) => {
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  };

  const formattedDate = formatDate(dateTime);

  const formattedTime = formatTime(dateTime);

  const formattedDateTime = `${formattedDate} at ${formattedTime}`;
  const formattedDateShow = `${formattedDate}`;
  return <Typography  variant="h6" color="blue-gray">{hideTime ? formattedDateShow : formattedDateTime}</Typography>;
};

export default Datetime;
