import importantDatesJson from "../ImportantDates/importantDates.json";
import { useState, useEffect,useRef } from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
  Typography,
} from "@material-tailwind/react";

function Icon({ open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className={`${open ? "rotate-180" : ""
        } h-5 w-5 transition-transform`}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
      />
    </svg>
  );
}


const ImportantDates = () => {

  const [openAcc, setOpenAcc] = useState([]);
  const [importantDates, setImportantDates] = useState([]);
  const firstFigcaptionRef = useRef(null);

  const getMonthName = (month) => {
    const monthNames = [
      "Janar", "Shkurt", "Mars", "Prill", "Maj", "Qershor",
      "Korrik", "Gusht", "Shtator", "Tetor", "Nëntor", "Dhjetor"
    ];
    return monthNames[month - 1] || "";
  };


  useEffect(() => {
    setImportantDates(importantDatesJson);
    setOpenAcc(new Array(importantDatesJson.length).fill(false));
  }, []);

 

  const handleOpenAcc = (index) => {
    const updatedOpenAcc = [...openAcc];
    updatedOpenAcc[index] = !updatedOpenAcc[index];
    setOpenAcc(updatedOpenAcc);

  };

  const scrollToIndex = () => {
    if (closestDate) {
      const index = importantDates.findIndex(
        (date) => date.id === closestDate.id
      );
  
      if (index !== -1) {
        const targetElement = document.getElementById(`secondFigcaption-${index}`);
        if (targetElement) {
          targetElement.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
         
        }
      }
    }
    
  };
  

  const calculateDaysLeft = (date) => {
    const currentDate = new Date();
    const targetDate = new Date(`${date.Year}-${date.Month}-${date.Date}`);
    const difference = targetDate - currentDate;
    const totalDays = Math.ceil(difference / (1000 * 3600 * 24));


    if (totalDays >= 2) {
      return totalDays + " Ditë të mbetura";
    } else if (totalDays === 0) {
      return ("Sot");
    } else if (totalDays === 1) {
      return ("Nesër");
    }
    else if (targetDate < currentDate) {
      return ("Data ka kaluar ");
    } else {
      return ("Error");
    }
  };
  //data e ardhshme
  const findClosestDate = () => {
    if (importantDates.length === 0) {
      return null;
    }
    const currentDate = new Date();
    let closestDate = null; // Initialize as null
    const currentDateValue = currentDate.valueOf();
    for (const date of importantDates) {
      const targetDate = new Date(`${date.Year}-${date.Month}-${date.Date}`);
      const targetDateValue = targetDate.valueOf();
      if (targetDateValue >= currentDateValue) {
        if (!closestDate) {
          closestDate = date;
        } else {
          const closestTargetDate = new Date(
            `${closestDate.Year}-${closestDate.Month}-${closestDate.Date}`
          );
          const closestTargetDateValue = closestTargetDate.valueOf();
          if (targetDateValue < closestTargetDateValue) {
            closestDate = date;
          }
        }
      }
    }
    return closestDate;
  };

  const closestDate = findClosestDate();

  const currentYear = new Date().getFullYear();


  return (

    <div className="relative top-20 h-full w-full  pb-10 flex flex-col gap-6  justify-between items-center">
      <div className="flex flex-col py-2 h-[265px] w-full border-blue-gray-500 shadow-lg shadow-black/10 bg-[#37474f] items-center " >
        <Typography variant="h3" color="white" className="px-2 text-center" >
          Datat e rëndësishme islame {currentYear}
          
        </Typography>
        <Typography variant="small" color="white" className="py-2">
          1445-1446 Hixhri
        </Typography>
        
        <figcaption
          id="firstFigcaption"
          className="rounded-xl bg-white py-4 px-6 w-80 sm:w-96 cursor-pointer"
          onClick={scrollToIndex}
          ref={firstFigcaptionRef}
        >
        
          <div className="flex justify-between">
            <Typography variant="small" color="black" className="font-semibold" >DATA E RADHËS</Typography>
          </div>
          {closestDate ? ( 
            
            <div className="relative"> 
            
              <Typography variant="small" color="black" className=" font-semibold">
                {`${closestDate.Date} ${getMonthName(closestDate.Month)} ${closestDate.Year}`}
              </Typography>
              <Typography variant="small" color="black" className="italic  font-semibold">
                {closestDate.PershkrimiDates}
              </Typography>
              <Typography variant="small" color="red">
                {calculateDaysLeft(closestDate)}
              </Typography>
              <button className="absolute right-0 md:top-10 top-11 md:text-sm text-xs italic underline text-blue-900  cursor-pointer" onClick={scrollToIndex}
          ref={firstFigcaptionRef}>Lexo më shumë</button>
            </div>
          ) : (
            <Typography variant="small" color="black" className="italic">
              No important dates found.
            </Typography>
          )}
        </figcaption>
      </div>

      {importantDates.map((data, index) => (

        <figcaption

        id={`secondFigcaption-${index}`} // Set a unique ID for each secondFigcaption
          key={index}
          // className="rounded-xl border border-white bg-white/75 py-4 px-6 shadow-lg shadow-black/10 w-96 sm:w-full lg:w-4/5 "
          className={`rounded-xl border border-white py-4 px-6  shadow-lg shadow-black/10 w-[350px]  lg:w-4/5 ${
            closestDate && closestDate.id === data.id ? 'bg-yellow-100' : 'bg-white'
          }`}
        >

          <Accordion className=""
           >
            <AccordionHeader  className="p-0 border-none text-base">
              {`${data.Date} ${getMonthName(data.Month)}  - ${data.PershkrimiDates}`}
            </AccordionHeader>
            {/* <AccordionHeader onClick={() => handleOpenAcc(index)} className="p-0 border-none text-base">
              {`${data.Date} ${getMonthName(data.Month)} ${data.Year} - ${data.PershkrimiDates}`}
            </AccordionHeader> */}
            <Typography variant="small" color="gray" >
              {`${data.IslamicDate} ${data.IslamicMonth} ${data.IslamicYear}`}
            </Typography>
            <Typography variant="small" color="red" >
              {calculateDaysLeft(data)}
            </Typography>
            <div >
              <AccordionBody className="text-sm border-t-2 " >
                {data.Description}
              </AccordionBody>
            </div>
          </Accordion>
        </figcaption>
      ))}

    </div>

  );
};

export default ImportantDates;